<template>
  <va-card class="announcement-card">
    <va-form
      tag="form"
      class="announcement-card__form"
      @submit.prevent="onsubmit()"
    >
      <va-input
        class="announcement-card__input"
        label="Title"
        v-model="title"
        disabled
      />
      <va-input
        class="announcement-card__input"
        label="Categories"
        v-model="tags"
      />
      <va-input
        class="announcement-card__input"
        label="Content"
        type="textarea"
        v-model="content"
        autosize
      />
      <va-file-upload
        class="announcement-card__input"
        type="gallery"
        file-types="image/*"
        v-model="thumbnail"
        dropzone
      />

      <va-button type="submit" class="mt-2 announcement-card__submit">
        Submit
      </va-button>
    </va-form>
  </va-card>
</template>

<script>
import { VaCard, VaInput, VaFileUpload, VaForm, VaButton } from "vuestic-ui";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EditAnnouncement",
  components: {
    VaCard,
    VaInput,
    VaFileUpload,
    VaForm,
    VaButton
  },
  data() {
    return {
      announcementSlug: this.$route.params.slug,
      title: null,
      tags: null,
      content: null,
      thumbnail: [],
    };
  },
  mounted() {
    this.fetAnnouncementItem(this.announcementSlug);
  },
  computed: {
    ...mapGetters("announcement", {
      announcement: "getAnnouncementItem"
    })
  },
  methods: {
    onsubmit() {
      // FIXME: Cant update announcement
      const formData = new FormData();
      const tagList = this.tags.split(",");

      if (tagList.length > 0) {
        formData.append("tags", tagList);
      }
      formData.append("content", this.content);
      this.editAnnouncement(this.announcementSlug, formData);
      this.$router.push({ name: "announcement-list" });
    },
    ...mapActions("announcement", ["editAnnouncement", "fetAnnouncementItem"]),
  },
  watch: {
    announcement: {
      handler(newValue) {
        if (newValue) {
          this.tags = newValue.tags.join(",");
          this.content = newValue.content;
        }
      },
      immediate: true
    },
  },
}
</script>

<style lang="scss" scoped>
.announcement-card {
  margin: 0 auto;
  max-width: 800px;

  &__input {
    margin: 0 auto;
    padding: 1rem 0;
    max-width: 400px;
  }

  &__submit {
    margin-top: 1rem;
    max-width: 400px;
  }

  &__editor {
    margin: 0 auto;
    padding: 1rem 0;
    max-width: 400px;
  }
}
</style>
